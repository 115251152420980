<template>
    <div class="vehicle-gallery">
        <h6 class="vehicle-name">{{ vehicle.name }}</h6>
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="vehicle-gallery-details">
                    <div class="row">
                        <div class="col">
                            <p class="vehicle-type">{{ vehicle.type }}</p>
                            <div class="row row-cols-2">
                                <div class="col mb-2">Number of Vehicles:</div>
                                <div class="col mb-2">{{ vehicle.count }}</div>
                                <div class="col mb-2">5 years or newer:</div>
                                <div class="col mb-2">{{ vehicle.newer ? 'Yes' : 'No' }}</div>
                            </div>
                        </div>
                        <div class="col-auto">
                            <div class="btn-group">
                                <button type="button" class="btn btn-link btn-sm">{{ editText }}</button>
                                <button type="button" class="btn btn-link btn-sm text-danger">{{ deleteText }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="vehicle-img-container">
                    <div class="avatar-lg vehicle-img" v-for="(image, index) in vehicle.images" :key="index">
                        <img :src="getImage(image.url)" alt="" class="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import imgPlaceholder from '../../assets/images/img-placeholder.svg';

    export default {
        name: 'VehicleGallery',
        props: {
            vehicle: {
                type: Object,
                required: true
            },
            editText: {
                type: String,
                default: 'Change'
            },
            deleteText: {
                type: String,
                default: 'Delete'
            }
        },
        methods: {
            getImage(image) {
                return image || imgPlaceholder;
            }
        }
    }
</script>
