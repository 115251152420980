<template>
    <div class="container-fluid px-0">
        <ol class="page-breadcrumb breadcrumb">
            <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
            <li class="breadcrumb-item active" aria-current="page">Vehicles</li>
        </ol>
        <div class="row">
            <div class="col">
                <div class="card card-rounded-lg">
                    <div class="card-body">
                        <h6 class="card-title">My Vehicles</h6>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Error mollitia necessitatibus nobis porro quae totam. Aperiam cum doloremque natus soluta? Autem fuga laborum libero nisi quidem reiciendis tempora unde voluptatem!</p>
                        <div class="mt-4">
                            <vehicle-gallery
                                v-for="(vehicle, index) in vehicles"
                                :key="index"
                                :vehicle="vehicle"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VehicleGallery from "../../components/VehicleGallery";

    export default {
        name: 'Vehicles',
        components: {
            VehicleGallery
        },
        data() {
            const vehicle = {
                name: 'Vehicle Info',
                type: 'Full Size Pick-Up Truck',
                count: 5,
                newer: true,
                images: [{url: ''}, {url: ''}, {url: ''}, {url: ''}]
            };

            const vehicles = [];

            for (let x = 0; x < 3; x++) {
                vehicles.push({...vehicle});
            }

            return {
                vehicles
            }
        }
    }
</script>
